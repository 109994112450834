<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <v-btn text @click="openFilterExpansion">
                      <v-icon>{{ openFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td
                  :class="
                    `text-center ${
                      item.company_settings
                        ? item.company_settings.suspended
                          ? 'grey--text'
                          : ''
                        : ''
                    }`
                  "
                >
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td
                  :class="
                    `text-uppercase ${
                      item.company_settings
                        ? item.company_settings.suspended
                          ? 'grey--text'
                          : ''
                        : ''
                    }`
                  "
                >
                  {{ item.name }}
                </td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'blue'
                              : 'blue'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('edit')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'teal'
                              : 'teal'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('settings')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'blue-grey darken-2'
                              : 'blue-grey darken-2'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="openConfigDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Configurations</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'red darken-4'
                              : 'red darken-4'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="openSubscriptionDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-youtube</v-icon>
                      </v-btn>
                    </template>
                    <span>Subscription</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('manage')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'deep-purple lighten-2'
                              : 'deep-purple lighten-2'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="manageCompany(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-launch</v-icon>
                      </v-btn>
                    </template>
                    <span>Manage</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('suspension')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'green'
                                : 'grey'
                              : 'grey'
                          }`
                        "
                        icon
                        @click="openSuspensionDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Suspension:
                      {{
                        item.company_settings
                          ? item.company_settings.suspended
                            ? "ON"
                            : "OFF"
                          : "OFF"
                      }}</span
                    >
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('delete')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :color="
                          `${
                            item.company_settings
                              ? item.company_settings.suspended
                                ? 'grey'
                                : 'red'
                              : 'red'
                          }`
                        "
                        :disabled="
                          item.company_settings
                            ? item.company_settings.suspended
                            : false
                        "
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Full Width Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? validateAddDialog()
          : dialogType === 'Edit'
          ? validateEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <details-container
              :data="data"
              :readonly="readonly"
              :dialogType="dialogType"
              ref="detailsContainer"
              @openSaveDialog="
                dialogType === 'Add'
                  ? openSaveAddDialog()
                  : dialogType === 'Edit'
                  ? openSaveEditDialog()
                  : ''
              "
            ></details-container>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Adding Company ---->

    <mini-dialog
      :dialog="saveAddDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddDialog()"
      @confirmDialog="addCompany()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Editing Company ---->

    <mini-dialog
      :dialog="saveEditDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditDialog()"
      @confirmDialog="editCompany()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Delete Company ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deleteCompany()"
    ></mini-dialog>

    <!---- Here is the Config Dialog ----->

    <full-width-dialog
      :dialog="dialogConfig"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'blue-grey darken-2'"
      :title="'Configurations'"
      :color="'blue-grey darken-2'"
      :saveButton="true"
      dark
      @saveDialog="confirmConfigDialog"
      @closeDialog="closeConfigDialog"
    >
      <v-container fluid>
        <v-row>
          <v-col md="2"></v-col>
          <v-col md="8">
            <configurations-details-container
              :jsonData="dataConfig"
              :vehicles="vehiclesArray"
              :selectedVehicles="selectedVehiclesArray"
              :dialogType="dialogType"
              ref="DetailsContainer"
            ></configurations-details-container>
          </v-col>
          <v-col md="2"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <mini-dialog
      :dialog="confirmConfig"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeconfirmConfigDialog"
      @confirmDialog="config()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Suspension Company ---->

    <mini-dialog
      :dialog="suspensionDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSuspensionDialog()"
      @confirmDialog="suspendCompany()"
    ></mini-dialog>

    <!---- Here is the Subscription Full Width Dialog ----->

    <full-width-dialog
      :dialog="subscriptionDialog"
      :title="'Subscription'"
      :color="'red darken-4'"
      :dialogLoading="subscriptionDialogLoading"
      :dialogLoadingColor="'red darken-4'"
      dark
      @closeDialog="closeSubscriptionDialog()"
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <v-container>
              <v-row>
                <v-card class="mx-auto px-4 py-2" max-width="344" outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        PROJECT
                      </div>
                      <v-list-item-title class="text-h5">
                        MARK TRANSPORT
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="openConfirmSubscriptionDialog()"
                      color="red darken-4"
                      outlined
                      rounded
                    >
                      Subscribe
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Project Subscription ---->

    <mini-dialog
      :dialog="saveSubscriptionDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmSubscriptionDialog()"
      @confirmDialog="subscribe()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";
import CompanyServices from "@/services/CompanyServices";
import DetailsContainer from "@/components/details_container/CompanyDetailsContainer";
import ConfigurationsDetailsContainer from "@/components/details_container/ConfigurationsDetailsContainer";

export default {
  components: {
    DetailsContainer,
    ConfigurationsDetailsContainer,
  },
  data: () => {
    return {
      title: "Company",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Name", sortable: false },
        { text: "Action", sortable: false, align: "center" },
      ],
      items: [],
      data: {},
      vehicleQueryParams: {
        search: "",
      },
      dataConfig: {
        notification_engine_status: false,
        notification_circuit_cutoff: false,
        notification_panic_button: false,
        notification_over_speeding: false,
        notification_idle: false,
        notification_pitstop: false,
      },
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
        approved: true,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      dialogConfig: false,
      confirmConfig: false,
      fullWidthDialog: false,
      dialogType: "",
      saveButton: false,
      fullWidthDialogLoading: false,
      readonly: false,
      saveAddDialog: false,
      saveEditDialog: false,
      deleteDialog: false,
      suspensionDialog: false,
      suspensionStatus: false,
      subscriptionDialog: false,
      subscriptionDialogLoading: false,
      saveSubscriptionDialog: false,
      vehiclesArray: [],
      selectedVehiclesArray: [],
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      try {
        let response = (await Services.getCompanies(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.dialogColor = "amber";
      this.saveButton = true;
      this.readonly = false;

      const deviceTypes = await this.setGPSDeviceTypes();

      const companies = await this.setCompanies();

      this.deviceTypes = await Promise.all(
        deviceTypes.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.companies = await Promise.all(
        companies.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.data.id = null;
      this.data.tracking_gps_device_type_id = null;
      this.data.company_id = null;
      this.data.imei_no = "";
      this.data.serial_no = "";
      this.data.unit_no = "";

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
    },
    validateAddDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveAddDialog() {
      this.saveAddDialog = true;
    },
    closeSaveAddDialog() {
      this.saveAddDialog = false;
    },
    async addGPSDevice() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        tracking_gps_device_type_id: data.tracking_gps_device_type_id,
        company_id: data.company_id,
        imei_no: data.imei_no,
        serial_no: data.serial_no,
        unit_no: data.unit_no,
      };

      try {
        const response = await Services.addGPSDevice(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openViewDialog(data) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.dialogColor = "blue";
      this.saveButton = false;
      this.readonly = true;

      this.data.id = data.id;
      this.data.business_name = data.name ? data.name.toUpperCase() : null;
      this.data.business_type = data.business_type
        ? data.business_type.toUpperCase()
        : null;
      this.data.business_address = data.address ? data.address : null;
      this.data.business_fax_no = data.fax_no ? data.fax_no : null;
      this.data.business_contact_no = data.phone_no ? data.phone_no : null;
      this.data.business_email = data.email ? data.email : null;
      this.data.image = data.image ? data.image : null;
      this.data.enrollment_date = data.enrollment_date
        ? data.enrollment_date
        : this.$dateFormat(new Date(), "YYYY-MM-DD");
      this.data.updated_at = data.updated_at;
      this.data.created_at = data.created_at;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
    },
    async openEditDialog(data) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.dialogColor = "teal";
      this.saveButton = true;
      this.readonly = false;

      this.data.id = data.id;
      this.data.business_name = data.name ? data.name.toUpperCase() : null;
      this.data.business_type = data.business_type
        ? data.business_type.toUpperCase()
        : null;
      this.data.business_address = data.address ? data.address : null;
      this.data.business_fax_no = data.fax_no ? data.fax_no : null;
      this.data.business_contact_no = data.phone_no ? data.phone_no : null;
      this.data.business_email = data.email ? data.email : null;
      this.data.image = data.image ? data.image : null;
      this.data.enrollment_date = data.enrollment_date
        ? data.enrollment_date
        : this.$dateFormat(new Date(), "YYYY-MM-DD");

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
    },
    validateEditDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveEditDialog() {
      this.saveEditDialog = true;
    },
    closeSaveEditDialog() {
      this.saveEditDialog = false;
    },
    async editCompany() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        name: data.business_name,
        business_type: data.business_type,
        address: data.business_address,
        phone_no: data.business_contact_no,
        fax_no: data.business_fax_no,
        email: data.business_email,
        image: data.image,
      };

      try {
        const response = await Services.editCompany(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteCompany() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteCompany(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async manageCompany(data) {
      const companyMenu = await Services.getCompanyMenu({
        company_id: data.id,
      });

      if (companyMenu.data.data.length) {
        this.$store.dispatch("dispatchCompanyMenu", companyMenu.data.data);
        this.$store.dispatch("dispatchMenu", companyMenu.data.data);
        this.$store.dispatch(
          "dispatchCompanySettings",
          companyMenu.data.company_settings
        );

        this.$store.dispatch("dispatchCompany", {
          id: data.id,
          name: data.name,
          code: data.code,
        });

        this.$router.push({
          path: this.$store.state.company_menu[0].link,
        });
      } else {
        this.$infoBar({
          type: "error",
          text: "No Access",
        });
      }
    },
    async openConfigDialog(item) {
      this.dialogConfig = true;
      this.dialogType = "Configuration";

      this.fullWidthDialogLoading = true;

      this.data.id = item.id;

      const getData = await CompanyServices.getConfig(item.id);

      if (getData.data.data) {
        const status = getData.data.data;

        this.dataConfig.notification_engine_status =
          status.notification_engine_status == 1 ? true : false;
        this.dataConfig.notification_circuit_cutoff =
          status.notification_circuit_cutoff == 1 ? true : false;
        this.dataConfig.notification_panic_button =
          status.notification_panic_button == 1 ? true : false;
        this.dataConfig.notification_over_speeding =
          status.notification_over_speeding == 1 ? true : false;
        this.dataConfig.notification_idle =
          status.notification_idle == 1 ? true : false;
        this.dataConfig.notification_pitstop =
          status.notification_pitstop == 1 ? true : false;
        this.dataConfig.notification_enable_alarm =
          status.notification_enable_alarm == 1 ? true : false;
        this.dataConfig.notification_geozone =
          status.notification_geozone == 1 ? true : false;
        this.dataConfig.notification_geofence =
          status.notification_geofence == 1 ? true : false;
        this.dataConfig.notification_hotspot =
          status.notification_hotspot == 1 ? true : false;

        this.dataConfig.dashboard_location =
          status.dashboard_location == 1 ? true : false;
        this.dataConfig.dashboard_speed =
          status.dashboard_speed == 1 ? true : false;
        this.dataConfig.dashboard_temperature =
          status.dashboard_temperature == 1 ? true : false;
        this.dataConfig.dashboard_gps_battery =
          status.dashboard_gps_battery == 1 ? true : false;
        this.dataConfig.dashboard_vehicle_battery =
          status.dashboard_vehicle_battery == 1 ? true : false;
        this.dataConfig.dashboard_snailtrail =
          status.dashboard_snailtrail == 1 ? true : false;

        this.dataConfig.dashboard_sms_alert =
          status.dashboard_sms_alert == 1 ? true : false;
        this.dataConfig.dashboard_email_alert =
          status.dashboard_email_alert == 1 ? true : false;
        this.dataConfig.dashboard_auto_email_reports =
          status.dashboard_auto_email_reports == 1 ? true : false;

        this.selectedVehiclesArray = status.selected_vehicles;
      }

      this.vehiclesArray = await this.setVehicles();

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 60);
    },
    closeConfigDialog() {
      this.dialogConfig = false;
    },
    confirmConfigDialog() {
      this.confirmConfig = true;
    },
    closeconfirmConfigDialog() {
      this.confirmConfig = false;
    },
    async config() {
      this.confirmLoading = true;

      const data = await this.$refs.DetailsContainer.computedData;

      const body = {
        company_id: this.data.id,
        notification_engine_status: data.notification_engine_status,
        notification_circuit_cutoff: data.notification_circuit_cutoff,
        notification_panic_button: data.notification_panic_button,
        notification_over_speeding: data.notification_over_speeding,
        notification_idle: data.notification_idle,
        notification_pitstop: data.notification_pitstop,
        notification_enable_alarm: data.notification_enable_alarm,
        notification_geozone: data.notification_geozone,
        notification_geofence: data.notification_geofence,
        notification_hotspot: data.notification_hotspot,
        dashboard_location: data.dashboard_location,
        dashboard_speed: data.dashboard_speed,
        dashboard_temperature: data.dashboard_temperature,
        dashboard_gps_battery: data.dashboard_gps_battery,
        dashboard_vehicle_battery: data.dashboard_vehicle_battery,
        dashboard_snailtrail: data.dashboard_snailtrail,
        dashboard_sms_alert: data.dashboard_sms_alert,
        dashboard_email_alert: data.dashboard_email_alert,
        dashboard_auto_email_reports: data.dashboard_auto_email_reports,
        selected_vehicles: data.selected_vehicles,
      };

      try {
        const response = await CompanyServices.setConfig(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeConfigDialog();
          this.closeconfirmConfigDialog();
          this.$infoBar({
            type: "success",
            text: "New Configuration has been set!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openSuspensionDialog(item) {
      this.suspensionDialog = true;
      this.suspensionStatus = item.company_settings
        ? !item.company_settings.suspended
        : true;

      this.data.id = item.id;
    },
    closeSuspensionDialog() {
      this.suspensionDialog = false;
    },
    async suspendCompany() {
      this.confirmLoading = true;

      const body = {
        suspended: this.suspensionStatus,
      };

      try {
        const response = await Services.suspendCompany(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text:
              this.title +
              (this.suspensionStatus
                ? " has been suspended!"
                : " suspension removed!"),
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openSubscriptionDialog(item) {
      this.subscriptionDialog = true;
      this.subscriptionDialogLoading = true;

      this.data.id = item.id;

      setTimeout(() => {
        this.subscriptionDialogLoading = false;
      }, 60);
    },
    closeSubscriptionDialog() {
      this.subscriptionDialog = false;
    },
    openConfirmSubscriptionDialog() {
      this.saveSubscriptionDialog = true;
    },
    closeConfirmSubscriptionDialog() {
      this.saveSubscriptionDialog = false;
    },
    async subscribe() {
      this.confirmLoading = true;

      const body = {
        project: "TRANSPORT",
      };

      try {
        const response = await Services.subscribe(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "Subscription Sucess!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setVehicles() {
      let data;

      try {
        this.vehicleQueryParams.company_id = this.data.id;

        const response = await CompanyServices.getVehicles(
          this.vehicleQueryParams
        );
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    closeAllDialog() {
      // this.closeAddDialog();
      // this.closeSaveAddDialog();
      this.closeViewDialog();
      this.closeEditDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
      this.closeSuspensionDialog();
      this.closeSubscriptionDialog();
      this.closeConfirmSubscriptionDialog();
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style></style>
