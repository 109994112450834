<template>
  <v-form ref="formChangePassword">
    <v-list subheader three-line>
      <v-subheader class="font-weight-bold title">Safety Alerts</v-subheader>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_engine_status"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Engine On/Off</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_engine_status ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_idle"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Idle</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_idle ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_pitstop"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Pitstop</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_pitstop ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_over_speeding"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Overspeeding</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_over_speeding ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_geozone"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Geozone</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_geozone ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

    <v-divider></v-divider>

    <v-list subheader three-line>
      <v-subheader class="font-weight-bold title">Security Alerts</v-subheader>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_panic_button"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Panic Button</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_panic_button ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_circuit_cutoff"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Circuit Cut-Off Alerts</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_circuit_cutoff ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_geofence"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Geofence Alerts</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_geofence ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.notification_hotspot"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Hotspot Alerts</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.notification_hotspot ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

    <v-divider></v-divider>

    <v-list subheader three-line>
      <v-subheader class="font-weight-bold title"
        >Dashboard Map View</v-subheader
      >
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_location"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Location</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_location ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_speed"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Speed</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_speed ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_temperature"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Temperature</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_temperature ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_gps_battery"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>GPS Battery</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_gps_battery ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_vehicle_battery"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Vehicle Battery</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_vehicle_battery ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_snailtrail"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Snailtrail</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_snailtrail ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

    <v-list subheader three-line>
      <v-subheader class="font-weight-bold title">Vehicle</v-subheader>
      <br />
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_sms_alert"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>SMS Alert</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_sms_alert ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="jsonData.dashboard_email_alert"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Email Alert</v-list-item-title>
              <v-list-item-subtitle>{{
                jsonData.dashboard_email_alert ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-list-item>
            <v-list-item-action>
              <!-- <v-switch
                v-model="jsonData.dashboard_auto_email_reports"
                color="success"
              ></v-switch> -->
              <v-switch
                v-model="enableReportsStatus"
                color="success"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                >Auto Email Reports&nbsp;
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="selectVehicleStatus"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="green--text"
                      @click="openVehicles()"
                    >
                      <v-icon>mdi-bus</v-icon></v-btn
                    >
                  </template>
                  <span>Select Vehicle/s</span>
                </v-tooltip>
              </v-list-item-title>
              <!-- <v-list-item-subtitle>{{
                jsonData.dashboard_auto_email_reports ? "ON" : "OFF"
              }}</v-list-item-subtitle> -->
              <v-list-item-subtitle>{{
                enableReportsStatus ? "ON" : "OFF"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

    <v-dialog
      v-model="disclaimerStatus"
      scrollable
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-text class="pt-5">
          <v-alert type="info">
            <span class="font-weight-black"> Notice: </span> Reports will be
            send in your e-mail at
            {{ $dateFormat(new Date(), "YYYY-MM-DD 23:59:59") }}.</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="disclaimerStatus = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectVehicleDialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>Select Vehicle/s</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px;">
          <v-checkbox
            v-for="(item, i) in vehicles"
            v-model="selectedVehiclesArr"
            dense
            :label="item.plate_no"
            :value="item.id"
            :key="i"
          ></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeVehicles()">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="selectVehicles()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  props: {
    jsonData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vehicles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedVehicles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dialogType: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      selectedVehiclesArr: [],
      selectVehicleDialog: false,
      enableReportsStatus: false,
      selectVehicleStatus: true,
      disclaimerStatus: false,
    };
  },
  created() {
    if (this.dialogType == "Configuration") {
      this.enableReportsStatus = this.jsonData.dashboard_auto_email_reports;
      this.selectedVehiclesArr = this.selectedVehicles;
    }
  },
  computed: {
    computedData() {
      return {
        notification_engine_status: this.jsonData.notification_engine_status
          ? 1
          : 0,
        notification_circuit_cutoff: this.jsonData.notification_circuit_cutoff
          ? 1
          : 0,
        notification_panic_button: this.jsonData.notification_panic_button
          ? 1
          : 0,
        notification_over_speeding: this.jsonData.notification_over_speeding
          ? 1
          : 0,
        notification_idle: this.jsonData.notification_idle ? 1 : 0,
        notification_pitstop: this.jsonData.notification_pitstop ? 1 : 0,
        notification_enable_alarm: this.jsonData.notification_enable_alarm
          ? 1
          : 0,
        notification_geozone: this.jsonData.notification_geozone ? 1 : 0,
        notification_geofence: this.jsonData.notification_geofence ? 1 : 0,
        notification_hotspot: this.jsonData.notification_hotspot ? 1 : 0,
        dashboard_location: this.jsonData.dashboard_location ? 1 : 0,
        dashboard_speed: this.jsonData.dashboard_speed ? 1 : 0,
        dashboard_temperature: this.jsonData.dashboard_temperature ? 1 : 0,
        dashboard_gps_battery: this.jsonData.dashboard_gps_battery ? 1 : 0,
        dashboard_vehicle_battery: this.jsonData.dashboard_vehicle_battery
          ? 1
          : 0,
        dashboard_snailtrail: this.jsonData.dashboard_snailtrail ? 1 : 0,
        dashboard_sms_alert: this.jsonData.dashboard_sms_alert ? 1 : 0,
        dashboard_email_alert: this.jsonData.dashboard_email_alert ? 1 : 0,
        // dashboard_auto_email_reports: this.jsonData.dashboard_auto_email_reports
        //   ? 1
        //   : 0,
        dashboard_auto_email_reports: this.enableReportsStatus ? 1 : 0,
        selected_vehicles: this.selectedVehiclesArr,
      };
    },
  },
  watch: {
    enableReportsStatus() {
      if (this.dialogType == "Configuration") {
        this.selectVehicleStatus = !this.enableReportsStatus;
        this.disclaimerStatus = this.enableReportsStatus;
      }
    },
  },
  methods: {
    openVehicles() {
      this.selectVehicleDialog = true;
      this.selectedVehiclesArr = this.selectedVehicles;
    },
    closeVehicles() {
      this.selectVehicleDialog = false;
      this.selectedVehiclesArr = [];
    },
    selectVehicles() {
      if (this.selectedVehiclesArr.length != 0) {
        this.selectVehicleDialog = false;
      } else {
        this.$infoBar({
          type: "error",
          text: "Select Vehicle/s First!",
        });
      }
    },
  },
};
</script>

<style scoped></style>
